<template>
  <div class="pa-3">
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-left">Тег</th>
          <th class="text-left">Действие</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!tags.length">
          <td class="text-center" colspan="2">Теги отсутствуют</td>
        </tr>

        <template v-else>
          <tr v-for="(tag, index) of tags" :key="index">
            <td>
              <v-text-field :value="tag" @input="handleTag($event, index)" />
            </td>

            <td>
              <v-btn icon color="error" @click="deleteByIndex(index)">
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>

    <div class="mt-4 text-right">
      <v-btn color="primary mr-4" @click="add">Новый тег</v-btn>

      <v-btn color="accent" :loading="updating" @click="save">Сохранить</v-btn>
    </div>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

export default {
  props: {
    ticker: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      updating: false,

      tags: []
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      try {
        const { keywords } = await this.$axios.$get(`stocks/${this.ticker}/news-keywords`);

        this.tags = [...keywords];
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error(`Не получены теги по тикеру: "${this.ticker}"` + html);
      }
    },
    async save() {
      try {
        const { keywords } = await this.$axios.$put(`stocks/${this.ticker}/news-keywords`, { keywords: this.tags });

        this.tags = [...keywords];

        this.$snackbar.success("Сохранено");
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Не обновлено:" + html);
      }
    },

    handleTag(value, index) {
      this.tags[index] = value;
    },

    add() {
      this.tags.push("");
    },
    deleteByIndex(index) {
      this.tags.splice(index, 1);
    }
  }
};
</script>
