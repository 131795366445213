<template>
  <v-select
    :value="sector"
    :items="options"
    label="Сектор"
    item-value="value"
    item-text="title"
    :disabled="disabled"
    @input="$emit('change', $event)"
  >
    <template v-slot:no-data>
      <div v-if="loading">Загрузка...</div>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    sector: {
      type: String,
      default: ""
    },
    emptyOption: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    options() {
      const sectors = this.$store.state.forms.stockSectors;

      return this.emptyOption ? [{ value: "", title: "Все" }].concat(sectors) : sectors;
    }
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      try {
        this.loading = true;

        await this.$store.dispatch("forms/fetchSectors");
      } catch {
        //ignore
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
