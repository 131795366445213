<template>
  <v-select
    v-model="value"
    :items="optionsCopy"
    item-value="value"
    item-text="title"
    label="Страна"
    return-object
    searchable
    clearable
  >
    <template v-slot:no-data>
      <div v-if="loading">Загрузка...</div>
    </template>

    <template v-slot:prepend-item>
      <v-list-item>
        <v-list-item-content>
          <v-text-field placeholder="Поиск" clearable @input="searchCountry" />
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    country: {
      type: Object,
      required: true
    },
    emptyOption: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      optionsCopy: []
    };
  },
  computed: {
    value: {
      get() {
        return {
          value: this.country.alpha2Code,
          title: this.country.name
        };
      },
      set(country) {
        this.$emit("change", { alpha2Code: country.value, name: country.title });
      }
    },
    options() {
      const countries = this.$store.state.forms.stockCountries;

      return this.emptyOption ? [{ value: "", title: "Все" }].concat(countries) : countries;
    }
  },
  watch: {
    options: {
      immediate: true,
      handler() {
        this.optionsCopy = this.options;
      }
    }
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      try {
        this.loading = true;

        await this.$store.dispatch("forms/fetchCountries");
      } catch {
        //ignore
      } finally {
        this.loading = false;
      }
    },
    searchCountry(country) {
      if (!country) {
        this.optionsCopy = this.options;
      }

      this.optionsCopy = this.optionsCopy.filter(
        value => value.title.toLowerCase().indexOf(country.toLowerCase()) > -1
      );
    }
  }
};
</script>
