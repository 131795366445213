<template>
  <v-dialog :value="isOpenModal" max-width="500px" @click:outside="$emit('close')">
    <v-card>
      <v-card-title>
        <span class="text-h6">Редактирование сплита</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <date-picker :value="fieldsSplit.date" label="Дата сплита" @input="setField('date', $event)" />
            </v-col>

            <v-col cols="12" sm="6">
              <div class="d-flex align-center">
                <div class="pr-2">
                  <v-text-field :value="fieldsSplit.from" label="Пропорция" @input="setField('from', $event)" />
                </div>
                :
                <div class="pl-2">
                  <v-text-field :value="fieldsSplit.to" @input="setField('to', $event)" />
                </div>
              </div>
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                :value="fieldsSplit.status"
                :items="statuses"
                label="Статус"
                item-value="uid"
                item-text="text"
                @input="setField('status', $event)"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="$emit('close')">Отмена</v-btn>
        <v-btn color="blue darken-1" text @click="$emit('update')">Обновить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { editorFields } from "~/mixins/multi-editor/editor-fields";

export default {
  mixins: [editorFields],
  props: {
    isOpenModal: {
      type: Boolean,
      required: true
    },
    fieldsSplit: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      statuses: [
        { uid: "published", text: "Опубликован" },
        { uid: "draft", text: "Неактивен" }
      ]
    };
  }
};
</script>
