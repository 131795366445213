<template>
  <div class="pa-3">
    <data-table
      ref="dataTable"
      :url="`stocks/${ticker}/dividends`"
      item-key="exDate"
      :headers="headers"
      :table-params="tableParams"
    >
      <template v-slot:top>
        <edit-dividend-modal
          :is-open-modal="isOpenModal"
          :fields-dividend="fieldsDividend"
          :period-types="periodTypes"
          :statuses="statuses"
          @change-field="changeField"
          @close="resetDividendFields"
          @save="saveDividend"
        />
      </template>

      <template v-slot:[`item.buyBefore`]="{ item: dividend }">
        {{ dividend.buyBefore | date }}
      </template>

      <template v-slot:[`item.recordDate`]="{ item: dividend }">
        {{ dividend.recordDate | date }}
      </template>

      <template v-slot:[`item.paymentDate`]="{ item: dividend }">
        {{ dividend.paymentDate | date }}
      </template>

      <template v-slot:[`item.amount`]="{ item: dividend }">
        {{ dividend.amount | number({ fullPrecision: true }) }}
      </template>

      <template v-slot="{ item: dividend }">
        {{ dividend.currency }}
      </template>

      <template v-slot:[`item.frequency`]="{ item: dividend }">
        {{ dividendPeriod(dividend.frequency) }}
      </template>

      <template v-slot:[`item.stockPrice`]="{ item: dividend }">
        {{ dividend.stockPrice | number({ fullPrecision: true }) }}
      </template>

      <template v-slot:[`item.status`]="{ item: dividend }">
        {{ getStatusHint(dividend.status) }}
      </template>

      <template v-slot:[`item.actions`]="{ item: dividend }">
        <div class="d-flex">
          <v-btn icon color="accent" @click="openEditedDividend(dividend)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>

          <v-btn icon color="error" @click="deleteDividend(dividend)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </data-table>

    <v-divider class="my-6" />

    <div class="text-h6 mb-3">Новый дивиденд</div>

    <create-dividend-form :ticker="ticker" :period-types="periodTypes" :statuses="statuses" />
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";
import { UpdateRuStockDividendDto } from "~/libs/stock/update-stock-dto";

import CreateDividendForm from "./create-dividend-form";
import EditDividendModal from "./edit-dividend-modal";
import { editorForm } from "~/mixins/multi-editor/editor-form";

export default {
  mixins: [editorForm],
  props: {
    ticker: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      headers: [
        { text: "Купить до", value: "buyBefore", sortable: false },
        { text: "Дата отсечки", value: "recordDate", sortable: false },
        { text: "Дата выплаты дивидендов", value: "paymentDate", sortable: false },
        { text: "Дивиденд", value: "amount", sortable: false },
        { text: "Валюта", value: "currency", sortable: false },
        { text: "Период", value: "frequency", sortable: false },
        { text: "Цена закрытия на дату 'купить до'", value: "stockPrice", sortable: false },
        { text: "Статус", value: "status", sortable: false },
        { text: "Действия", value: "actions", sortable: false }
      ],
      tableParams: {
        ticker: this.ticker
      },
      periodTypes: [
        { uid: "weekly", text: "Неделя" },
        { uid: "monthly", text: "Месяц" },
        { uid: "quarterly", text: "Квартал" },
        { uid: "semi-annual", text: "Полгода" },
        { uid: "nine-months", text: "9 месяцев" },
        { uid: "annual", text: "Год" },
        { uid: "unspecified", text: "Не установлен" }
      ],
      statuses: [
        { uid: "declared", text: "Объявленный" },
        { uid: "approved", text: "Утвержденный" },
        { uid: "unapproved", text: "Неутвержденный" }
      ],
      isOpenModal: false,
      fieldsDividend: {}
    };
  },
  methods: {
    refreshTable() {
      return this.$refs.dataTable.update();
    },
    openEditedDividend(dividend) {
      this.isOpenModal = true;
      this.fieldsDividend = { ...dividend };
    },
    async deleteDividend({ exDate }) {
      try {
        await this.$axios.$delete(`stocks/${this.ticker}/dividends/${exDate}`);
        await this.$refs.dataTable.update();

        this.$snackbar.success("Дивиденд удален");
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Не удалось удалить дивиденд: " + html);
      }
    },
    resetDividendFields() {
      this.isOpenModal = false;
      this.fieldsDividend = {};
    },
    dividendPeriod(typePeriod) {
      return this.periodTypes.filter(period => period.uid === typePeriod)?.[0]?.text ?? "Не установлен";
    },
    getStatusHint(value) {
      return this.statuses.filter(status => status.uid === value)?.[0]?.text ?? "Не установлен";
    },
    async saveDividend() {
      try {
        if (this.fieldsDividend?.amount && typeof this.fieldsDividend.amount === "string") {
          this.fieldsDividend.amount = this.fieldsDividend.amount.replace(",", ".");
        }

        await this.$axios.$patch(
          `stocks/${this.ticker}/dividends/${this.fieldsDividend.exDate}`,
          new UpdateRuStockDividendDto(this.fieldsDividend)
        );
        await this.$refs.dataTable.update();

        this.$snackbar.success("Дивиденд обновлен");
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Не удалось обновить дивиденд: " + html);
      } finally {
        this.isOpenModal = false;
        this.fieldsDividend = {};
      }
    },
    changeField(payload) {
      this.commitChange(this.fieldsDividend, payload);
    }
  },
  components: {
    CreateDividendForm,
    EditDividendModal
  }
};
</script>
