<template>
  <v-form autocomplete="off" @submit.prevent="createsplit">
    <v-text-field v-model="split.ticker" label="Тикер" disabled />

    <date-picker v-model="split.date" label="Дата сплита" />

    <div class="d-flex align-center">
      <div class="pr-2">
        <v-text-field v-model="split.from" label="Пропорция" />
      </div>
      :
      <div class="pl-2">
        <v-text-field v-model="split.to" />
      </div>
    </div>

    <v-select v-model="split.status" :items="statuses" label="Статус" item-value="uid" item-text="text" />

    <div class="mt-4 text-right">
      <v-btn type="submit" color="primary mr-4" :loading="loading">Добавить сплит</v-btn>
    </div>
  </v-form>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

const defaultcreateSplitDto = {
  ticker: "",
  date: "",
  to: "",
  from: "",
  status: ""
};

Object.freeze(defaultcreateSplitDto);

export default {
  props: {
    ticker: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      split: { ...defaultcreateSplitDto, ticker: this.ticker },
      statuses: [
        { uid: "published", text: "Опубликован" },
        { uid: "draft", text: "Неактивен" }
      ]
    };
  },
  computed: {
    createSplitDto() {
      this.split.from = this.split.from.replace(",", ".");

      this.split.to = this.split.to.replace(",", ".");

      return { ...this.split };
    }
  },
  methods: {
    async createsplit() {
      this.loading = true;

      try {
        await this.$axios.$post(`stocks/splits`, this.createSplitDto);

        await this.$parent.refreshTable();

        this.clear();

        this.$snackbar.success("Сплит создан");
      } catch (e) {
        const html = new ApiValidationError(e, Object.keys(this.createSplitDto)).toHtml();

        this.$snackbar.error("Не удалось создать сплит: " + html);
      } finally {
        this.loading = false;
      }
    },

    clear() {
      this.split = { ...defaultcreateSplitDto };
    }
  }
};
</script>
