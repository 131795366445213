<template>
  <div class="pa-3">
    <stock-overview :stock="stock" @change-field="changeField" @update-ticker="$emit('update-ticker', $event)" />

    <v-divider class="my-3" />

    <stock-media v-model="logo" :stock="stock" />

    <v-divider class="my-3" />

    <div class="text-right">
      <v-btn color="accent" :loading="updating" @click="handleSave">Сохранить</v-btn>
    </div>
  </div>
</template>

<script>
import { editorForm } from "~/mixins/multi-editor/editor-form";
import { encodeFileToBase64 } from "~/core/helpers";

import { UpdateStockDto } from "~/libs/stock/update-stock-dto";

import StockOverview from "./overview";
import StockMedia from "./media";

export default {
  mixins: [editorForm],
  props: {
    stock: {
      type: Object,
      required: true
    },
    updating: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      logo: null
    };
  },
  watch: {
    stock() {
      this.logo = null;
    }
  },
  methods: {
    async handleSave() {
      const logoConverted = await encodeFileToBase64(this.logo);

      this.$emit("update-stock", new UpdateStockDto(this.stock, logoConverted));
    },

    changeField(payload) {
      this.commitChange(this.stock, payload);
    }
  },
  components: {
    StockOverview,
    StockMedia
  }
};
</script>
