<template>
  <v-dialog :value="isOpenModal" max-width="600px" @click:outside="$emit('close')">
    <v-card>
      <v-card-title>
        <span class="text-h6">Редактирование дивиденда</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <date-picker
                :value="fieldsDividend.recordDate"
                label="Дата отсечки"
                @input="setField('recordDate', $event)"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <date-picker
                :value="fieldsDividend.paymentDate"
                label="Дата выплаты дивидендов"
                @input="setField('paymentDate', $event)"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field :value="fieldsDividend.amount" label="Дивиденд" @input="setField('amount', $event)" />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field :value="fieldsDividend.currency" label="Валюта" @input="setField('currency', $event)" />
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                :value="fieldsDividend.frequency"
                :items="periodTypes"
                label="Период"
                item-value="uid"
                item-text="text"
                @input="setField('frequency', $event)"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                :value="fieldsDividend.stockPrice"
                label="Цена закр. на дату 'купить до'"
                @input="setField('stockPrice', $event)"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                :value="fieldsDividend.status"
                :items="statuses"
                label="Статус"
                item-value="uid"
                item-text="text"
                @input="setField('status', $event)"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="$emit('close')">Отмена</v-btn>
        <v-btn color="blue darken-1" text @click="$emit('save')">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { editorFields } from "~/mixins/multi-editor/editor-fields";

export default {
  mixins: [editorFields],
  props: {
    isOpenModal: {
      type: Boolean,
      required: true
    },
    fieldsDividend: {
      type: Object,
      required: true
    },
    periodTypes: {
      type: Array,
      required: true
    },
    statuses: {
      type: Array,
      required: true
    }
  }
};
</script>
