<template>
  <v-form autocomplete="off" @submit.prevent="createDividend">
    <date-picker v-model="dividend.buyBefore" label="Купить до" />

    <date-picker v-model="dividend.recordDate" label="Дата отсечки" />

    <date-picker v-model="dividend.paymentDate" label="Дата выплаты дивидендов" />

    <v-text-field v-model="dividend.amount" label="Дивиденд" />

    <v-text-field v-model="dividend.currency" label="Валюта" />

    <v-select v-model="dividend.frequency" :items="periodTypes" label="Период" item-value="uid" item-text="text" />

    <v-select v-model="dividend.status" :items="statuses" label="Статус" item-value="uid" item-text="text" />

    <div class="mt-4 text-right">
      <v-btn type="submit" color="primary mr-4" :loading="loading">Добавить дивиденд</v-btn>
    </div>
  </v-form>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

const defaultCreateDividendDto = {
  buyBefore: "",
  recordDate: "",
  paymentDate: "",
  amount: "",
  currency: "RUB",
  frequency: "unspecified",
  status: "declared"
};

Object.freeze(defaultCreateDividendDto);

export default {
  props: {
    ticker: {
      type: String,
      required: true
    },
    periodTypes: {
      type: Array,
      required: true
    },
    statuses: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      dividend: { ...defaultCreateDividendDto }
    };
  },
  computed: {
    createDividendDto() {
      this.dividend.amount = this.dividend.amount.replace(",", ".");

      return { ...this.dividend };
    }
  },
  methods: {
    async createDividend() {
      this.loading = true;

      try {
        await this.$axios.$post(`stocks/${this.ticker}/dividends`, this.createDividendDto);
        await this.$parent.refreshTable();

        this.clear();

        this.$snackbar.success("Дивиденд создан");
      } catch (e) {
        const html = new ApiValidationError(e, Object.keys(this.createDividendDto)).toHtml();

        this.$snackbar.error("Не удалось создать дивиденд: " + html);
      } finally {
        this.loading = false;
      }
    },

    clear() {
      this.dividend = { ...defaultCreateDividendDto };
    }
  }
};
</script>
