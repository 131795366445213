<template>
  <div class="pa-3">
    <v-row class="props-list">
      <v-col md="2">Название акции</v-col>
      <v-col md="10">
        <v-text-field
          :value="cases.nominative"
          label="Именительный"
          @input="changeField({ field: 'nominative', value: $event })"
        />
        <v-text-field
          :value="cases.genitive"
          label="Родительный"
          @input="changeField({ field: 'genitive', value: $event })"
        />
        <v-text-field
          :value="cases.dative"
          label="Дательный"
          @input="changeField({ field: 'dative', value: $event })"
        />
        <v-text-field
          :value="cases.accusative"
          label="Винительный"
          @input="changeField({ field: 'accusative', value: $event })"
        />
        <v-text-field
          :value="cases.ablative"
          label="Творительный"
          @input="changeField({ field: 'ablative', value: $event })"
        />
        <v-text-field
          :value="cases.prepositional"
          label="Предложный"
          @input="changeField({ field: 'prepositional', value: $event })"
        />
      </v-col>
    </v-row>

    <div class="text-right">
      <v-btn color="accent" :loading="updating" @click="handleSave">Сохранить</v-btn>
    </div>
  </div>
</template>

<script>
import { UpdateStockDto } from "~/libs/stock/update-stock-dto";

import { editorForm } from "~/mixins/multi-editor/editor-form";

export default {
  mixins: [editorForm],
  props: {
    stock: {
      type: Object,
      required: true
    },
    updating: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    cases() {
      return (
        this.stock.nameCases ?? {
          nominative: "",
          genitive: "",
          dative: "",
          accusative: "",
          ablative: "",
          prepositional: ""
        }
      );
    }
  },

  methods: {
    async handleSave() {
      this.stock.nameCases = this.cases;

      this.$emit("update-stock", new UpdateStockDto(this.stock));
    },
    changeField(payload) {
      this.commitChange(this.cases, payload);
    }
  }
};
</script>
