<template>
  <div>
    <h2 class="mb-3">Медиа</h2>

    <v-row>
      <v-col sm="6" lg="4" align="center">
        <v-card class="py-2" :class="{ 'grey darken-1': dark }" elevation="3" :dark="dark">
          <v-card-title>
            <div>Логотип</div>
          </v-card-title>

          <v-card-text>
            <v-img class="rounded-circle" :src="`${stock.logo}?${imgKey}`" width="88" height="88" contain />

            <v-file-input v-model="logo" label="Выбрать файл" prepend-icon="mdi-camera" />
          </v-card-text>

          <v-card-actions>
            <v-spacer />

            <v-btn icon @click="dark = !dark">
              <v-icon>mdi-circle-slice-4</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    stock: {
      type: Object,
      required: true
    },
    value: {
      default: null,
      validator() {
        return true;
      }
    }
  },
  data() {
    return {
      dark: true,

      imgKey: 0
    };
  },
  computed: {
    logo: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      }
    }
  },
  watch: {
    logo(newVal) {
      if (newVal === null) this.imgKey += 1;
    }
  }
};
</script>
