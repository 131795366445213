<template>
  <div class="pa-3">
    <data-table ref="dataTable" url="stocks/splits" :headers="headers" :table-params="tableParams">
      <template v-slot:[`item.date`]="{ item: split }">
        {{ split.date | date }}
      </template>

      <template v-slot:[`item.from`]="{ item: split }">
        {{ formateNumber(split.from) }}:{{ formateNumber(split.to) }}
      </template>

      <template v-slot:[`item.source`]="{ item: split }">
        {{ sourceMap[split.source] }}
      </template>

      <template v-slot:[`item.createdAt`]="{ item: split }">
        {{ split.createdAt | dateTime }}
      </template>

      <template v-slot:[`item.status`]="{ item: split }">
        {{ statusMap[split.status] }}
      </template>

      <template v-slot:[`item.actions`]="{ item: split }">
        <div class="d-flex">
          <v-btn icon color="accent" @click="openEditedSplit(split)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>

          <v-btn icon color="error" @click="deleteSplit(split)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </data-table>

    <edit-split-modal
      :is-open-modal="isOpenModal"
      :fields-split="fieldsSplit"
      @close="resetSplitFields"
      @change-field="changeField"
      @update="updateSplit"
    />

    <v-divider class="my-6" />

    <div class="text-h6 mb-3">Новый сплит</div>

    <create-split-form :ticker="ticker" />
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";
import { formatNumber } from "~/core/filters/numbers";
import { editorForm } from "~/mixins/multi-editor/editor-form";

import { UpdateStockSplitDto } from "~/libs/stock/update-stock-dto";

import EditSplitModal from "~/components/stocks/ticker/splits/edit-modal";
import CreateSplitForm from "~/components/stocks/ticker/splits/create-form";

export default {
  mixins: [editorForm],
  props: {
    ticker: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      headers: [
        { text: "Дата сплита", value: "date", sortable: true },
        { text: "Пропорция", value: "from", sortable: false, class: "nobr" },
        { text: "Источник", value: "source", sortable: false, class: "nobr" },
        { text: "Дата добавления", value: "createdAt", sortable: true, class: "nobr" },
        { text: "Статус", value: "status", sortable: false, class: "nobr" },
        { text: "Действие", value: "actions", sortable: false }
      ],
      sourceMap: {
        admin: "Ручной ввод",
        api: "API"
      },
      statusMap: {
        published: "Опубликован",
        applied: "Применен",
        draft: "Неактивен"
      },
      fieldsSplit: {},
      isOpenModal: false
    };
  },
  computed: {
    tableParams() {
      return { stock: { ticker: this.ticker }, sort: { by: "date", order: "desc" } };
    }
  },
  methods: {
    refreshTable() {
      return this.$refs.dataTable.update();
    },

    resetSplitFields() {
      this.isOpenModal = false;

      this.fieldsSplit = {};
    },

    async updateSplit() {
      try {
        if (this.fieldsSplit?.to && typeof this.fieldsSplit.to === "string") {
          this.fieldsSplit.to = this.fieldsSplit.to.replace(",", ".");
        }

        if (this.fieldsSplit?.from && typeof this.fieldsSplit.from === "string") {
          this.fieldsSplit.from = this.fieldsSplit.from.replace(",", ".");
        }

        await this.$axios.$patch(`stocks/splits/${this.fieldsSplit.id}`, new UpdateStockSplitDto(this.fieldsSplit));
        await this.$refs.dataTable.update();

        this.$snackbar.success("Сплит обновлен");
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Не удалось обновить сплит: " + html);
      } finally {
        this.isOpenModal = false;

        this.fieldsSplit = {};
      }
    },

    async deleteSplit({ id }) {
      try {
        await this.$axios.$delete(`stocks/splits/${id}`);
        await this.$refs.dataTable.update();

        this.$snackbar.success("Сплит удален");
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Не удалось удалить сплит: " + html);
      }
    },

    openEditedSplit(split) {
      this.isOpenModal = true;

      this.fieldsSplit = { ...split };
    },
    changeField(payload) {
      this.commitChange(this.fieldsSplit, payload);
    },

    formateNumber(number) {
      const options = Number.isInteger(number) ? { fractionDigits: 0 } : {};

      return formatNumber(number, options);
    }
  },
  components: {
    EditSplitModal,
    CreateSplitForm
  }
};
</script>
