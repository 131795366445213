<template>
  <div>
    <btn-back class="mb-4" :to="{ name: 'stocks-index' }" />

    <template v-if="stock">
      <h2 class="mb-4">{{ stock.name }}</h2>

      <v-sheet elevation="1">
        <v-tabs background-color="deep-purple secondary" center-active dark hide-slider>
          <v-tab>Обзор</v-tab>
          <v-tab>Дивиденды</v-tab>
          <v-tab>Прогнозы</v-tab>
          <v-tab>Новости</v-tab>
          <v-tab>Сплиты</v-tab>
          <v-tab>Синонимы</v-tab>
          <v-tab v-if="isRussianStock">По падежам</v-tab>

          <v-tab-item>
            <stock-summary
              :stock="stock"
              :updating="updating"
              @update-ticker="updateTicker"
              @update-stock="updateStock"
            />
          </v-tab-item>

          <v-tab-item>
            <stock-dividends :ticker="stock.ticker" />
          </v-tab-item>

          <v-tab-item>
            <stock-forecasts :ticker="stock.ticker" :prices="stock.prices" :table-params="{ ticker: stock.ticker }" />
          </v-tab-item>

          <v-tab-item>
            <stock-news :ticker="ticker" />
          </v-tab-item>

          <v-tab-item>
            <stock-splits :ticker="ticker" />
          </v-tab-item>

          <v-tab-item>
            <stock-synonyms :stock="stock" />
          </v-tab-item>

          <v-tab-item v-if="isRussianStock">
            <stock-cases :stock="stock" :updating="updating" @update-stock="updateStock" />
          </v-tab-item>
        </v-tabs>
      </v-sheet>
    </template>

    <h2 v-else-if="!loading" class="mb-4">Акция по тикеру "{{ ticker }}" не найдена</h2>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

import StockForecasts from "~/components/shared/stock-forecasts/table";

import StockSummary from "~/components/stocks/ticker/summary";
import StockCases from "~/components/stocks/ticker/cases";
import StockSynonyms from "~/components/stocks/ticker/synonyms";
import StockSplits from "~/components/stocks/ticker/splits";
import StockDividends from "~/components/stocks/ticker/dividends";
import StockNews from "~/components/stocks/ticker/news";

export default {
  data() {
    return {
      stock: null,

      loading: true,
      updating: false
    };
  },
  created() {
    this.fetchStock();
  },
  computed: {
    ticker() {
      return this.$route.params.ticker;
    },
    isRussianStock() {
      return this.stock.origin === "russian";
    }
  },
  methods: {
    async fetchStock() {
      this.loading = true;

      try {
        this.stock = await this.$axios.$get(`stocks/${this.ticker}`);
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error(`Не получена информация по тикеру: "${this.ticker}"` + html);
      } finally {
        this.loading = false;
      }
    },

    async updateStock(payload) {
      this.updating = true;

      try {
        this.stock = await this.$axios.$patch(`stocks/${this.ticker}`, JSON.stringify(payload), {
          headers: {
            "Content-Type": "application/json"
          }
        });

        this.$snackbar.success("Обновлено");
      } catch (e) {
        const html = new ApiValidationError(e, ["logo"]).toHtml();

        this.$snackbar.error("Не обновлено" + html);
      } finally {
        this.updating = false;
      }
    },

    updateTicker(stock) {
      this.stock = stock;
    }
  },
  components: {
    StockForecasts,
    StockSummary,
    StockCases,
    StockSynonyms,
    StockSplits,
    StockDividends,
    StockNews
  }
};
</script>
